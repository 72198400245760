import React, { useState } from "react"
import Seo from "../../../components/seo"
import Layout from "../../../app/layout"
import { Link, graphql } from "gatsby"

import { Row, Pagination, Col, Breadcrumb, Card } from "antd"
import { ChevronRight } from "../../../components/Icon"

import Container from "../../../components/Container"
import Masthead from "../../../components/Masthead"
import { useI18next } from "gatsby-plugin-react-i18next"

// const { Paragraph } = Typography

const PersiapanKebijakan = ({
  data: {
    awardeeDeparturePolicies: data = {
      page_setting: {},
      purpose: [],
      videos: [],
    },
  },
}) => {
  const { t } = useI18next()
  const [filter, setFilter] = useState({
    page: 1,
  })

  return (
    <Layout>
      <Seo title="Kebijakan" />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Persiapan Keberangkatan</Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.header_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={
          data.page_setting.header_video_thumbnail ||
          data.page_setting.header_image
        }
        title={data.page_setting.header_title}
        videoId={data.page_setting.header_video_url}
        useVideo={!!data.page_setting.header_use_video}
        textBottom={t("Selengkapnya")}
      />

      <div className="section section-secondary text-large">
        <Container>
          <div
            dangerouslySetInnerHTML={{
              __html: data.page_setting.header_description,
            }}
          />
        </Container>
      </div>

      <div className="section text-medium" style={{ marginTop: 40 }}>
        <Container>
          <div className="text-center">
            <h2 className="section-title">
              {t("Tujuan Persiapan Keberangkatan")}
            </h2>
          </div>
          <Row gutter={40} style={{ margin: "32px -15px 0 -15px" }}>
            {data.purpose.map(function (item, i) {
              return (
                <Col
                  span={24}
                  md={8}
                  key={i}
                  className="lined-section"
                  style={{
                    marginBottom: 15,
                    padding: "0 15px",
                  }}
                >
                  <h5 style={{ marginTop: 12 }}>{item.title}</h5>
                  <div
                    style={{ marginTop: 12 }}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
      <div className="section">
        <Container>
          <div className="text-center" style={{ marginBottom: 24 }}>
            <h3 className="section-title">{t("Video PK")}</h3>
          </div>
          <Row gutter={20}>
            {data.videos
              .slice((filter.page - 1) * 9, filter.page * 9)
              .map(function (item, i) {
                return (
                  <Col span={24} md={8} key={i}>
                    <a
                      href={item.video_url}
                      rel="noreferrer"
                      target="_blank"
                      style={{ marginBottom: 24 }}
                      aria-label={t("Video PK")}
                    >
                      <Card
                        bordered={false}
                        className="card-news"
                        cover={
                          <img
                            alt={item.title}
                            src={item.video_thumbnail_url}
                          />
                        }
                      >
                        <div className="card-meta">
                          <h3 className="card-title">{item.title}</h3>
                        </div>
                      </Card>
                    </a>
                  </Col>
                )
              })}
          </Row>
          <Row justify="center" style={{ marginBottom: 40, marginTop: 40 }}>
            <Pagination
              current={filter.page}
              total={data.videos.length}
              onChange={page => setFilter(p => ({ ...p, page }))}
              defaultPageSize={9}
            />
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query awardeeDeparturePoliciesQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    awardeeDeparturePolicies(lang: { eq: $language }) {
      videos {
        publish
        title
        video_thumbnail_url
        video_url
      }
      purpose {
        title
        description
      }
      page_setting {
        header_description
        header_title
        header_image
        header_use_video
        header_video_thumbnail
        header_video_url
        slug
      }
    }
  }
`

export default PersiapanKebijakan
